@import 'src/assets/variables';

.about {
  height: max-content;
  background-color: $white;
  padding: 50px 100px;
  display: flex;
  align-items: center;
}

.fixed {
  position: fixed;
}

.about-wrapper {
  display: flex;
  justify-content: space-between;
}

.info {
  width: 50%;
  opacity: 0;
  transition: opacity 1s;
  position: relative;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.name {
  font-size: $solut-name;
  line-height: 100px;
  font-weight: 900;
  color: $globalAccent;
}

.solut {
  color: $globalAccent;
}

.atomic {
  color: $redAtomic;
}

.about .about-img {
  transform: translate(-1000px, 0);
  transition: transform 0.6s;
}

.about-img img {
  display: block;
  width: 45vw;
  height: 85vh;
}

.description {
  margin-top: 15px;
}

.description-list {
  padding: 0;
  margin-left: 20px;
}

.description-list-item {
  font-size: 1.4vw;
  white-space: pre-line;
}

.description-list-item::marker {
  color: $globalAccent !important;
}

@keyframes fromLeft {
  0% {
    transform: translate(-100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@media (width < 980px) {
  .about {
    font-size: 16px;
    padding: 20px 10px;
    height: 100%;
  }

  .about-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .info {
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }

  .description {
    font-size: 18px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .description-list-item {
    font-size: 18px;
  }

  .name {
    line-height: 22vw;
    font-weight: 900;
    font-size: 20vw;
    text-align: center;
    text-shadow: 10px 5px #fff;
  }

  .about-img img {
    margin-top: 5vh;
    width:95vw;
    height: 30vh;
  }
}
