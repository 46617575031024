@import '../../../assets/variables';

.review-card-wrapper {
  border-radius: 30px;
  padding: 20px;
  border: 2px solid $globalAccent;
}

.review-description {
  margin-bottom: 20px;
}

@media (width < 980px) {
  .review-description,
  .review-author {
    font-size: 18px;
  }
}
