@import 'src/assets/variables';

.projects-wrapper {
  display: flex;
  height: max-content;
  padding: 50px 100px;
  position: relative;
  justify-content: center;
  flex-direction: column;
  background-color: $globalAccent
}

.projects-heading {
  margin: 20px 0;
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (width < 980px) {
  .projects-wrapper {
    padding: 0 10px 20px 10px;
  }
}
