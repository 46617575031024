@import 'src/assets/variables';

.file-link {
  color: $black;
}

.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-right: 15px;
  border-radius: 15px;
  transition: background-color linear 0.2s;
  cursor: pointer;
  background-color: $white;

  .file-name {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .file-name img {
    height: 40px;
    width: 30px;
    border-radius: 0;
    margin-right: 20px;
  }

  .link-icon {
    border-radius: 0;
    height: 30px;
    margin-left: 5px;
  }
}

.file:not(:last-child) {
  margin-bottom: 20px;
}

.file:hover {
  background-color: #DDE7F0;
}
