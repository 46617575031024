@import '../../assets/variables';

.charts {
  height: max-content;
  background-color: $white;
  padding: 30px 100px;
  display: flex;
  align-items: center;
}

.charts-heading button {
  padding: 0 20px;
  margin: 10px 0;
}

.buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.button-active {
  background: $globalAccent;
  color: white;
  height: 50px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
}

.chart-diagram {
  cursor: pointer;
}

.charts-description {
  margin-bottom: 10px;
}


@media (width < 980px) {
  .charts {
    padding: 10px 10px;
    height: auto;
  }

  .charts-heading button {
    margin: 0 10px;
  }

  .charts-description {
    font-size: 18px;
    margin: 10px
  }

  .buttons-wrapper {
    gap: 10px;
  }
}
