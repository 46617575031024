@import 'src/assets/variables';

.screenshots-and-technologies-wrapper {
  display: flex;
  height: max-content;
  padding: 50px 100px;
  position: relative;
  justify-content: center;
  flex-direction: column;
}

.screenshots-and-technologies-heading {
  margin: 20px 0;
}

.screenshots {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    background-color: $white;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $globalAccent;
  }
}

.screenshots img {
  max-width: 700px;
  margin-bottom: 10px;
  object-fit: fill;
}

.screenshots-and-technologies-description,
.technologies-list {
  margin-bottom: 20px;
}

@media (width < 980px) {
  .screenshots-and-technologies-wrapper {
    padding: 0 10px 20px 10px;
  }

  .screenshots img {
    max-width: 350px;
    margin-bottom: 10px;
  }

  .screenshots-and-technologies-description,
  .technologies-label,
  .technologies-list {
    font-size: 18px;
    margin: 0 10px;
  }

  .screenshots-and-technologies-description,
  .technologies-list {
    margin-bottom: 10px;
  }
}
