@import 'src/assets/variables';

.wedo-wrapper {
  height: 100vh;
  background: $white;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wedo {
  display: flex;
  align-items: start;
  width: 100%;
}

.wedo-heading {
  opacity: 0;
  transition: all 1s;
}

.wedo-img {
  transform: translate3d(-100vw, 0, 0);
  transition: transform 1s;
  display: flex;

}

.wedo-img img {
  width: 50vw;
  height: 55vh;
}

.project-description {
  margin-top: 15px;
  margin-bottom: 50px;
}

.post-description {
  margin-top: 15px;
  font-size: 1vw;
}

.point {
  background-color: $globalAccent;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 30px;
}

.point.red {
  background-color: $redAtomic;
}


.wedo-info {
  display: flex;
  flex-direction: column;
}

.wedo-lists {
  display: flex;
  gap: 34px;
  margin-bottom: 30px;
}

.wedo-list {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.wedo-list-label {
  font-size: 30px;
  color: $globalAccent;
}

.point-wrapper {
  margin-top: 20px;

  opacity: 0;
  transform: translate3d(100vw, 0, 0);
  transition: all 0.8s;
}

.wedo-list div {
  font-size: $p;
  list-style-type: none;
  display: flex;
  align-items: center;
}


@media (width < 980px) {
  .wedo-heading {
    width: 100vw;
  }

  .project-description {
    font-size: 18px;
    margin-left: 0;
    margin-right: 0;
  }

  .wedo {
    flex-direction: column-reverse;
  }

  .wedo-img {
    width: 100%;
    flex-direction: column-reverse;
  }

  .point-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .point-wrapper p {
    width: 80%;
  }

  .wedo-lists {
    flex-wrap: wrap;
  }

  .wedo-list {
    align-items: center;
    width: 100%;
  }

  .wedo-list-label {
    font-size: 22px;
  }

  .wedo-info {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: end;
    width: 100vw;
  }

  .wedo-img img {
    width: 95vw;
    height: 30vh;
    margin-top: 5vh;
  }

  .wedo-heading {
    margin: 0;
  }

  .point-wrapper p {
    font-size: 18px;
  }

  .post-description {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
  }
}


@media (width < 980px) {
  .wedo-wrapper {
    padding: 10px 10px;
    height: 100%;

  }

  .point {
    margin: 0;
  }

  .wedo-info {
    align-items: center;
    width: 100%;
  }
}
