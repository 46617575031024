@import 'src/assets/variables';

.footer {
  padding: 10px 150px;
  height: 15vh;
  background: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-documents {
  color: white;
  font-size: 20px;
}

.footer-project-info {
  color: white;
  font-size: 18px;
  text-align: center;
}

.footer-contacts {
  display: flex;
  align-items: center;
  font-size: 18px;
  width: max-content;
}

.footer-contacts a {
  color: white;
}

.footer-documents:hover,
.footer-contacts a:hover {
  color: #0AACBE;
  transition: all .3s;
}

@media (width < 980px) {
  .footer {
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .footer-documents {
    margin-bottom: 20px;
  }
}
