@import '../../../assets/variables';

.chart-one {
  border-radius: 30px;
  padding: 10px;
}


.chart-three {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chart-one-wrapper {
  display: flex;
}

.chart-one-left-menu {
  width: 20%;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chart-one-right-menu {
}

.work-day-chart {
  display: block;
  margin: 10px;
  width: 65vw;
  height: 120px;
  border-radius: 0;
}

.work-day-chart-time {
  z-index: 1000;
  position: absolute;
  margin-left: 10px;
  width: 65vw;
  display: flex;
  justify-content: space-between;
}

.work-day-chart-big {
  position: relative;
  margin: 10px;
  margin-top: -106px;
  transform: rotate(180deg);
  width: 65vw;
  height: 400px;
  overflow-x: scroll;
  overflow-y: hidden;
  border-radius: 0;
  scroll-behavior: smooth;
}

.work-day-chart-big::-webkit-scrollbar {
  height: 100px;
}

.work-day-chart-big::-webkit-scrollbar-track {
  -webkit-box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0) inset;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.work-day-chart-big::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 50px;
  background: rgba(0, 171, 189, 0.7);
}

.work-day-chart-big img {
  transform: rotate(180deg) translate(0, 100px);
}

.chart-one-buttons {
  display: flex;
}

.chart-one-diagram {
  padding: 10px;
  background: #DDE7F0;
  margin-top: 20px;
  border-radius: 15px;
}

.chart-one-button {
  background: $globalAccent;
  color: $white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
}

.chart-one-charts>p {
  margin-left: 10px;
  position: absolute;
}

.diagram-one-description {
  padding: 10px;

}

.diagram-one-legend p {
  margin-left: 5px;
}

.diagram-one-legend {
  display: flex;
  align-items: center;
  margin-top: 10px;


}

.diagram-one {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

//
.diagram-one-percent {
  width: 50px;
  height: 20px;
  display: block;
  margin-left: 10px;
}

//
//.diagram-one-percent:nth-child(3) {
//  width: 50px;
//  height: 20px;
//  background: green;
//  display: block;
//  margin-left: 10px;
//
//}
//.diagram-one-percent:nth-child(5) {
//  width: 50px;
//  height: 20px;
//  background: blue;
//  display: block;
//  margin-left: 10px;
//
//}
//.diagram-one-percent:nth-child(7) {
//  width: 50px;
//  height: 20px;
//  background: #0AACBE;
//  display: block;
//  margin-left: 10px;
//
//}
//.diagram-one-percent:nth-child(9) {
//  width: 50px;
//  height: 20px;
//  background: #949494;
//  display: block;
//  margin-left: 10px;

//}

@media (width < 980px) {
  .chart-one-wrapper {
    flex-direction: column;
  }
  .chart-one-charts {
    align-items: center;
  }
  .chart-one-left-menu {
    width: 100%;
  }
  .chart-one-right-menu {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .chart-one {
    height: 100%;
    margin-top: 20px;
  }
  .chart-one-diagram {
    height: auto;
    display: flex;
    justify-content: center;
  }
  .diagram-one-percent {
    margin-right: 20px;
  }
}
