@import '../../../assets/variables';

.project-card-wrapper {
  display: flex;
  border-radius: 30px;
  padding: 20px;
  background-color: $white;
}

.project-image {
  width: 30%;
  margin-right: 20px;
}

.project-name {
  font-size: 32px;
  margin-bottom: 10px;
}

.project-description {
  margin-bottom: 20px;
}

.project-description,
.project-results {
  white-space: pre-line;
}

@media (width < 980px) {
  .project-card-wrapper {
    flex-wrap: wrap;
  }

  .project-image {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .project-name {
    font-size: 22px;
  }

  .project-description,
  .project-results-label,
  .project-results {
    font-size: 18px;
  }
}
