@import 'src/assets/variables';

.spheres {
  height: 100vh;
  background-color: $globalAccent;
  padding: 50px 100px;
  display: flex;
  align-items: center;
}

.heading-ref {
  transform: translate3d(-100vw, 0, 0);
  transition: all 0.5s;
  margin-bottom: 40px;
}

.spheres-wrapper {
}


.spheres-wrapper div {
  display: flex;
}

.spheres-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  transform: translate3d(100vw, 0, 0);
  transition: all 1s;
  max-width: 50%;
}

.spheres-buttons p{
  cursor: pointer;
}

.spheres-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

//.heading {
//  text-transform: uppercase;
//  color: $white;
//  font-size: 48px;
//  font-weight: 900;
//  white-space: pre-line
//}

.info-wrapper {
  margin-top: 20px;
  transition: all 1s;
  width: 100%;
  line-height: 1.5;

}

.spheres-description {
  //font-size: $p;
  margin-right: $between-margin;
  transform: translate3d(-200vw, 0, 0);
  transition: all 1s;
  color: $white;
  max-width: 50%;
}

.img {
  height: 60vh;
  transform: translate3d(200vw, 0, 0);
  transition: all 1s;
}


.button {
  font-size: 18px;
  background: none;
  margin: 5px 10px;
  border: 1px solid $white;
  border-radius: 30px;
  color: $white;
  padding: 2vh 4vh;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.active {
  color: $globalAccent;
  background: $white;
}

@media (width < 1400px) {
  .img {
    height: 40vh;
  }
}

@media (width < 980px) {
  .button p {
    font-size: 18px;
  }

  .button {
    margin: 5px 5px;
  };
  .spheres-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    transition: all 1s;
    max-width: 100%;
  }
  .spheres {
    font-size: 18px;
    padding: 20px 0;
    height: 100%;
  }

  .spheres-description {
    max-width: 100%;
    font-size: 18px;
    margin: 0 10px;
    align-items: center;
  }
  .spheres-header {
    flex-direction: column;

  }
  .spheres-wrapper {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .info-wrapper {
    width: 100vw;
    flex-direction: column;
  }

  .info-wrapper img {
    margin: 1vh auto 0;
    width: 95vw;
    height: 30vh;
  }

  .img {
    height: 30vh;
  }

  .heading-ref {
    margin-bottom: 0;
  }
}
