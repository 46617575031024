// colors
$globalAccent: #0AACBE;
$globalAccentTransparent: #0AACBEDA;
$globalAccentScroll: #A1E4EB;
$neutral: #949494;
$white: #fff;
$blue-white: #E6FDFF;

$red: #E03227;
$redAtomic: #E03227;
$black: #191A1D;

// fonts
$solut-name: 100px;
$h1: 64px;
$h2: 48px;
$p: 20px;

//margins
$width-margin: 150px;
$block-margin: 50px;
$between-margin: 20px;

@media screen and (max-width: 300px) {
  $h1: 50px;

}
