@import 'src/assets/variables';

.documents {
  background-color: $globalAccentTransparent;
  height: 100vh;
  background-image: url('../../../public/images/main-background.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
}

.logo {
  padding: 0 20px;
  cursor: pointer;
  width: max-content;
}

.documents-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  padding: 30px 20px 20px;
  margin: 20px;
}

.documents-container-header {
  font-size: 40px;
  margin-bottom: 40px;
  margin-left: 10px;
}

.files-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100vh - 350px);
  overflow-x: auto;

  &::-webkit-scrollbar {
    background-color: $white;
    border-radius: 10px;
    display: block;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $globalAccentScroll;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    margin-left: 5px;
  }
}
