@import 'src/assets/variables';

.reviews-wrapper {
  display: flex;
  height: max-content;
  padding: 50px 100px;
  position: relative;
  justify-content: center;
  flex-direction: column;
}

.reviews-heading {
  margin: 20px 0;
}

.buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.button-blue {
  margin: 0;
  padding: 0 20px;
}

.button-active {
  background: $globalAccent;
  color: white;
  height: 50px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  margin: 0;
  padding: 0 20px;
}

@media (width < 980px) {
  .reviews-wrapper {
    padding: 0 10px 20px 10px;
  }

  .buttons-wrapper {
    gap: 10px;
  }
}
