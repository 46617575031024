@import 'src/assets/variables';

.payback-wrapper {
  display: flex;
  height: max-content;
  padding: 50px 100px;
  position: relative;
  justify-content: center;
  flex-direction: column;
}

.payback-heading {
  opacity: 0;
  transition: all 1s;
  margin-bottom: 20px;
}

.payback-img {
  transform: translate3d(-100vw, 0, 0);
  transition: all 1s;
}

.payback-info {
  min-width: 30vw;
}

.payback-info.cost-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
}

.ps-text-wrapper {
  margin-top: 20px;
  margin-bottom: 100px;
}

.payback-info.cost-info .payback-list {
  gap: 30px;
}

.payback-info.cost-info li {
  border-radius: 30px;
  padding: 20px;
  border: 2px solid #0AACBE;
}

.payback-img img {
  margin: 30px;
}

.payback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.payback-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 100px;
  padding: 0;
}

.payback-list.left {
  width: 40%;
}

.payback-list.right {
  width: 60%;
}

.payback-info li {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.cost-wrapper{
  margin-bottom: 50px;
}

.cost-wrapper li {
  list-style-type: none;
  margin-bottom: 30px;
}

.ps-text {
  margin-top: 4px;
  font-size: 1vw;
}

.number {
  font-size: 128px;
  font-weight: 900;
  color: $globalAccent;
  line-height: 125px;
}

.big-text {
  font-size: 50px;
  color: $globalAccent;
}

.sub-tittle {
  font-size: 32px;
  text-transform: uppercase;
  white-space: pre-line;
  transform: translate3d(100vw, 0, 0);
  transition: all 1s;
}

.additional-text {
  margin-top: 10px;
  font-size: 20px;
  white-space: pre-line;
}

.number-wrapper {
  opacity: 0;
  transition: all 1s;
}

@media (width < 1360px) {
  .big-text {
    font-size: 40px;
  }

  .number {
    font-size: 100px;
  }
}

@media (width < 1142px) {
  .big-text {
    font-size: 32px;
  }

  .number {
    font-size: 80px;
  }
}

@media (width < 980px) {
  .payback-wrapper {
    flex-direction: column;
    padding: 0 10px;
    margin: 5vh 0;
    height: 100%;
  }

  .payback {
    flex-direction: column;
    align-items: center;
  }

  .payback-info li {
    margin-left: 0;
    align-items: center;
  }

  .payback-list {
    padding: 0;
  }

  .number-wrapper {
    text-align: center;
  }

  .sub-tittle {
    font-size: 30px;
    text-align: center;
  }

  .payback-img img {
    width: 95vw;
    height: 30vh;
    margin: 30px;
  }

  .ps-text {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
  }

  .payback-info.cost-info {
    flex-wrap: wrap;
  }

  .payback-info.cost-info ul {
    margin: 0;
  }

  .payback-list.left {
    width: 100%;
  }

  .payback-list.right {
    width: 100%;
  }

  .big-text {
    font-size: 50px;
  }

  .number {
    font-size: 128px;
  }
}
